import Toast from 'bootstrap/js/dist/toast';

class Flash {
  #flashContainer = null;
  #flashTemplate = null;
  #loaded = false;

  constructor() {
    this.#defer(()=>this.#documentLoaded());
  }

  alert(msg) {
    this.#defer(()=>{
      var element = this.#renderByCategory(msg, "alert");
      this.#show(element);
    });
  }

  notice(msg) {
    this.#defer(()=>{
      var element = this.#renderByCategory(msg, "notice");
      this.#show(element);
    });
  }

  // use #defer() if context from DOM is required, that may or may not be
  // loaded when the initial method is called.
  #defer(func) {
    if (this.#loaded) {
      func();
    } else {
      document.addEventListener("DOMContentLoaded", ()=>{
        this.#loaded = true;
        func();
      });
    }
  }

  #show(element) {
    // initialize bootstrap toast component and show it
    // (show is required, or else timer for autohide will not start)
    new Toast(element).show();
    this.#flashContainer.prepend(element);
  }

  #render(msg, header, color) {
    var template = this.#flashTemplate.cloneNode(true);
    template.content.querySelector(".flash-color").classList.add(color);
    template.content.querySelector(".flash-title").textContent = header;
    template.content.querySelector(".flash-message").textContent = msg;
    return template.content.firstElementChild;
  }

  #renderByCategory(msg, category) {
    var header = "Fehler";
    var color = "bg-danger";
    if (category != "alert") {
      header = "Information";
      color = "bg-success";
    }
    return this.#render(msg, header, color);
  }

  #renderTemplates() {
    document.querySelectorAll("template.flash-alert").forEach((element)=>{
      this.alert(element.innerHTML);
      element.remove();
    });
    document.querySelectorAll("template.flash-notice").forEach((element)=>{
      this.notice(element.innerHTML);
      element.remove();
    });
  }

  #documentLoaded() {
    this.#flashContainer = document.getElementById("flash-container");
    this.#flashTemplate = document.getElementById("flash-template");
    this.#renderTemplates();
  }
}

const flash = new Flash();
export default flash;
